<template>
	<h1>Form Dropdown</h1>
	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">import FormDropdown from '@/components/FormDropdown'

import { ddSep } from '@/use/Constants' // Universal separator
export default {
	components: { FormDropdown },
	data() {
		return {
			myChoice: '',
			ddOptions: [
				{
					display: 'Pick one', // &#60;-- Inside the dropdown
					passiveDisplay: 'Hello', // &#60;-- HTML Display (optional)
					hidden: false, // &#60;-- Hides the option (optional)
					disabled: true, // &#60;-- Disables the option (optional)
					selected: true, // &#60;-- Sets default value (optional)
				},
				{
					value: 'spaghetti-carbonara',
					display: 'Spaghetti Carbonara',
				},
				{
					value: 'ravioli',
					display: 'Ravioli',
					onSelect: () => { alert('Bingo') }
					wall: 'do xyz' // &#60;-- Triggers nonUserBlock('do xyz')
					// which displays a flash alert to non-users:
					// "Please log in to do xyz."
				},
				ddSep,
				{
					value: 'borscht',
					display: 'Borscht',
				}
				
			]
		}
	}
}</code></pre>
	</div>
	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>
			You have chosen: <em>{{ myChoice }}</em>
		</p>
		<br />
		<FormDropdown :options="ddOptions" v-model="myChoice" label="Favorite Pasta" />
		<br />
		<p>The same functionality is mirrored in the ActionMenu component.</p>
		<ActionMenu :options="ddOptions" />
	</div>
</template>

<script>
import FormDropdown from '@/components/FormDropdown'
import ActionMenu from '@/components/ActionMenu'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocFormsDropdown',
	components: { FormDropdown, ActionMenu },
	setup() {
		injectMetaData({
			title: 'Doc: Forms › Dropdown',
		})
	},
	data() {
		return {
			myChoice: '',
			ddOptions: [
				{
					display: 'Pick one',
					passiveDisplay: 'Hello',
					hidden: false,
					disabled: true,
					selected: true,
				},
				{
					value: 'spaghetti-carbonara',
					display: 'Spaghetti Carbonara',
					selected: true, // <-- This sets default value
				},
				{
					value: 'ravioli',
					display: 'Ravioli',
					onSelect: () => {
						alert('Bingo')
					},
					wall: 'do xyz',
				},
				{
					value: 'penne-alla-vodka',
					display: 'Penne alla Vodka',
				},
			],
			html: [
				'<FormDropdown',
				'	v-model="myChoice"				// Data model',
				'	:options="ddOptions"			// Dropdown contents',
				'	label="Favorite Pasta"			// If not set, no label will be rendered',
				'',
				'									// Shared:',
				'	:mT/mR/mB/mL="30"				// Set single margin',
				'	:margin="[10,10,10,10]"			// Set all margins at once',
				'	:width="300"					// Default width is 250',
				'/>',
			].join('\n'),
		}
	},
}
</script>

<style scoped lang="scss"></style>
